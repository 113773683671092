import { getData } from './request.js'

/**
 * 查询任务
 */
export function getSchedulerList(data) {
    return getData({
        url: 'mapi/scheduled/list',
        method: 'get',
        data: data
    })
}


/**
 * 运行任务
 */
export function switchScheduler(data) {
    return getData({
        url: 'mapi/scheduled/switch',
        method: 'put',
        data: data
    })
}


/**
 * 新建任务
 */
export function addScheduler(data) {
    return getData({
        url: 'mapi/scheduled/add',
        method: 'post',
        data: data
    })
}

/**
 * 修改任务
 */
export function updateScheduler(data) {
    return getData({
        url: 'mapi/scheduled/update',
        method: 'put',
        data: data
    })
}

/**
 * 删除任务
 */
export function deleteScheduler(data) {
    return getData({
        url: 'mapi/scheduled/delete',
        method: 'delete',
        data: data
    })
}
